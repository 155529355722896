<script setup lang="ts">
import ApplicationLogo from '@/Components/ApplicationLogo.vue'
import Dropdown from '@/Components/Dropdown.vue'
import DropdownLink from '@/Components/DropdownLink.vue'
import { router } from '@inertiajs/vue3'

defineProps<{
  title?: string
  subtitle?: string
  isError?: boolean
  hideUser?: boolean,
}>()

defineSlots<{
  title?: () => any
  subtitle?: () => any
  "header-title"?: () => any
  "header-subtitle"?: () => any
}>()

const logout = () => {
  router.post('/logout')
}
</script>

<template>
  <div class="pt-3 flex flex-col w-full flex-1">
    <div v-if="! hideUser && $page.props.auth?.user?.is_super_admin" class="self-end pr-4">
      <Dropdown align="right" width="48">
        <template #trigger>
          <span class="inline-flex rounded-md">
              <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none focus:bg-gray-50 dark:focus:bg-gray-700 active:bg-gray-50 dark:active:bg-gray-700 transition ease-in-out duration-150">

                <img v-if="$page.props.auth.user?.avatar" class="h-8 w-8 rounded-full object-cover mr-2" :src="$page.props.auth.user.avatar" :alt="$page.props.auth.user.short_display_name">

                <span>{{ $page.props.auth.user?.short_display_name }}</span>

                  <svg class="ms-2 -me-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
              </button>
          </span>
        </template>

        <template #content>
          <!-- Account Management -->
          <div class="block px-4 py-2 text-xs text-gray-400">
            Manage Account
          </div>

          <DropdownLink href="/">
            Log In to Team
          </DropdownLink>

          <DropdownLink href="/profile">
            Profile
          </DropdownLink>

          <div class="border-t border-gray-200 dark:border-gray-600" />

          <!-- Authentication -->
          <form @submit.prevent="logout">
            <DropdownLink as="button">
              Log Out
            </DropdownLink>
          </form>
        </template>
      </Dropdown>
    </div>
    <div class="mt-auto mx-auto">
      <div v-if="isError" class="flex items-center justify-center mb-10">
        <ApplicationLogo />
      </div>
      <div class="flex items-center justify-center space-x-5">

        <svg v-if="isError" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="text-red-500 w-[40px]">
          <path fill="currentColor" d="M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2 0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3 0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z" class=""></path>
        </svg>
        <ApplicationLogo v-else />
        <div class="bg-white bg-opacity-50 w-px h-[40px]"></div>
        <div>
          <slot name="header-title">
            <h1 class="text-3xl text-white font-semibold">
              <slot name="title">{{ title }}</slot>
            </h1>
          </slot>
          <slot name="header-subtitle">
            <div v-if="subtitle ?? $slots['subtitle']" class="mt-1 mb-2 text-white">
              <slot name="subtitle">{{ subtitle }}</slot>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
